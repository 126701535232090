import React, { useEffect, useState } from 'react';
import './CardsCantidad.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faImage, faTachometerAlt, faCode, faTable, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import baseURL from '../../url';

export default function CardsCantidad() {
    const [productos, setProductos] = useState([]);
    const [banners, setBanners] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [codigos, setCodigos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [mesas, setMesas] = useState([]);
    const [clientes, setClientes] = useState([]);

    useEffect(() => {
        cargarProductos();
        cargarBanners();
        cargarCategoria();
        cargarCodigos();
        cargarPedidos();
        cargarMesas();
        cargarClientes();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setBanners(data.banner || []);
            })
            .catch(error => console.error('Error al cargar banners:', error));
    };

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const cargarCodigos = () => {
        fetch(`${baseURL}/codigosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCodigos(data.codigos || []);
            })
            .catch(error => console.error('Error al cargar códigos:', error));
    };

    const cargarPedidos = () => {
        fetch(`${baseURL}/pedidoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setPedidos(data.pedidos || []);
            })
            .catch(error => console.error('Error al cargar pedidos:', error));
    };

    const cargarMesas = () => {
        fetch(`${baseURL}/mesaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setMesas(data.mesas || []);
            })
            .catch(error => console.error('Error al cargar mesas:', error));
    };

    const cargarClientes = () => {
        fetch(`${baseURL}/clientesGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setClientes(data.clientes || []);
            })
            .catch(error => console.error('Error al cargar clientes:', error));
    };

    return (
        <div className='CardsCantidad'>
            <Anchor to={`/dashboard/productos`} className='cardCantidad'>
                <FontAwesomeIcon icon={faBook} className='icons' />
                <div>
                    <h3>Productos</h3>
                    <h2>{productos.length}</h2>
                </div>
            </Anchor>
            <Anchor to={`/dashboard/banners`} className='cardCantidad'>
                <FontAwesomeIcon icon={faImage} className='icons' />
                <div>
                    <h3>Banners</h3>
                    <h2>{banners.length}</h2>
                </div>
            </Anchor>
            <Anchor to={`/dashboard/categorias`} className='cardCantidad'>
                <FontAwesomeIcon icon={faTachometerAlt} className='icons' />
                <div>
                    <h3>Categorías</h3>
                    <h2>{categorias.length}</h2>
                </div>
            </Anchor>
            <Anchor to={`/dashboard/codigos`} className='cardCantidad'>
                <FontAwesomeIcon icon={faCode} className='icons' />
                <div>
                    <h3>Códigos</h3>
                    <h2>{codigos.length}</h2>
                </div>
            </Anchor>
            <Anchor to={`/dashboard/mesas`} className='cardCantidad'>
                <FontAwesomeIcon icon={faTable} className='icons' />
                <div>
                    <h3>Mesas</h3>
                    <h2>{mesas.length}</h2>
                </div>
            </Anchor>
            <Anchor to={`/dashboard/pedidos`} className='cardCantidad'>
                <FontAwesomeIcon icon={faClipboardList} className='icons' />
                <div>
                    <h3>Pedidos</h3>
                    <h2>{pedidos.length}</h2>
                </div>
            </Anchor>
            <Anchor to={`/dashboard/clientes`} className='cardCantidad'>
                <FontAwesomeIcon icon={faUser} className='icons' />
                <div>
                    <h3>Clientes</h3>
                    <h2>{clientes.length}</h2>
                </div>
            </Anchor>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import baseURL from '../../url';
import NewMesa from '../NewMesa/NewMesa';
import './MesasData.css'
export default function MesasData() {
    const [mesas, setMesas] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoEstado, setNuevoEstado] = useState('');
    const [nuevaMesa, setNuevaMesa] = useState('');
    const [mesa, setMesa] = useState({});
    const [selectedSection, setSelectedSection] = useState('texto');

    useEffect(() => {
        cargarMesas();

    }, []);


    const cargarMesas = () => {
        fetch(`${baseURL}/mesaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setMesas(data.mesas || []);
                console.log(data.mesas)
            })
            .catch(error => console.error('Error al cargar mesas:', error));
    };

    const eliminar = (idMesa) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/mesaDelete.php?idMesa=${idMesa}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarMesas();
                    })
                    .catch(error => {
                        console.error('Error al eliminar contacto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setMesa(item);
        setNuevoEstado(item.estado);
        setNuevaMesa(item.mesa);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };



    const handleUpdateText = (idMesa) => {
        const payload = {
            estado: nuevoEstado !== '' ? nuevoEstado : mesa.estado,
            mesa: nuevaMesa !== '' ? nuevaMesa : mesa.mesa,
        };

        fetch(`${baseURL}/mesaPut.php?idMesa=${idMesa}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                    console.log(idMesa)
                    console.log(payload)
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarMesas();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };



    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };
    return (
        <div className='BannerContainer'>
            <ToastContainer />
            <NewMesa />

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>

                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>

                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Mesa</legend>
                                    <input
                                        type="text"
                                        value={nuevaMesa !== '' ? nuevaMesa : mesa.mesa}
                                        onChange={(e) => setNuevaMesa(e.target.value)}
                                    />
                                </fieldset>


                                <fieldset>
                                    <legend>Estado</legend>
                                    <select
                                        value={nuevoEstado !== '' ? nuevoEstado : mesa.estado}
                                        onChange={(e) => setNuevoEstado(e.target.value)}
                                    >
                                        <option value="libre">Libre</option>
                                        <option value="ocupada">Ocupada</option>
                                    </select>
                                </fieldset>

                            </div>
                            <button className='btnPost' onClick={() => handleUpdateText(mesa.idMesa)} >Guardar</button>

                        </div>

                    </div>
                </div>
            )}

            <div className='mesasGrap'>

                {mesas.map(item => (
                    <div className={`cardMesa ${item.estado === 'libre' ? 'bg-green' : 'bg-red'}`} key={item.idMesa}>
                        <div className='deFLexBtnMesa'>
                            <button className='eliminar' onClick={() => eliminar(item.idMesa)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <h4>{item.estado}</h4>
                            <button className='editar' onClick={() => abrirModal(item)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        </div>
                        <h3>{item.mesa}</h3>


                    </div>
                ))}


            </div>
        </div>
    );
};

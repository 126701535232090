import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSync, faEye, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './Clientes.css'; // Asegúrate de que este archivo CSS esté actualizado como en el primer archivo
import baseURL from '../../url';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function ClientesData() {
    const [clientes, setClientes] = useState([]);
    const [selectedSection, setSelectedSection] = useState('texto');
    const [modalVisible, setModalVisible] = useState(false);
    const [filtroContacto, setFiltroContacto] = useState('');
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroDireccion, setFiltroDireccion] = useState('');
    const [filtroBarrio, setFiltroBarrio] = useState('');
    const [filtroComplemento, setFiltroComplemento] = useState('');
    const [clienteEditado, setClienteEditado] = useState({
        contacto: '',
        nombre: '',
        direccion: '',
        barrio: '',
        complemento: '',
    });
    const [erroresValidacion, setErroresValidacion] = useState({});

    useEffect(() => {
        cargarClientes();
    }, []);

    const cargarClientes = () => {
        fetch(`${baseURL}/clientesGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setClientes(data.clientes || []);
            })
            .catch(error => console.error('Error al cargar clientes:', error));
    };

    const eliminarCliente = (contacto) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/clientesDelete.php?contacto=${contacto}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            Swal.fire('Error', data.error, 'error');
                        } else {
                            Swal.fire('¡Eliminado!', data.mensaje, 'success');
                        }
                        cargarClientes();
                    })
                    .catch(error => {
                        console.error('Error al eliminar cliente:', error);
                        toast.error('Error al eliminar cliente.');
                    });
            }
        });
    };

    const abrirModalCliente = (item) => {
        setClienteEditado(item);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
        setErroresValidacion({});
        setClienteEditado({
            contacto: '',
            nombre: '',
            direccion: '',
            barrio: '',
            complemento: '',
        });
    };

    const handleChangeCliente = (e) => {
        const { name, value } = e.target;
        setClienteEditado({
            ...clienteEditado,
            [name]: value,
        });
    };

    const guardarCambiosCliente = () => {
        const errores = {};
        Object.keys(clienteEditado).forEach((key) => {
            if (clienteEditado[key].trim() === '') {
                errores[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} no puede estar vacío.`;
            }
        });

        if (Object.keys(errores).length > 0) {
            setErroresValidacion(errores);
            return;
        }

        fetch(`${baseURL}/clientesPut.php`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(clienteEditado),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire('Error', data.error, 'error');
                } else {
                    Swal.fire('¡Guardado!', 'Los cambios han sido guardados correctamente.', 'success');
                    cerrarModal();
                    cargarClientes();
                }
            })
            .catch(error => {
                console.error('Error al guardar cambios del cliente:', error);
                toast.error('Error al guardar cambios del cliente.');
            });
    };

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(clientes);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Clientes");
        XLSX.writeFile(workbook, "clientes.xlsx");
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF();
        const tableColumn = ["Contacto", "Nombre", "Dirección", "Barrio", "Complemento"];
        const tableRows = [];

        clientes.forEach(cliente => {
            const clienteData = [
                cliente.contacto,
                cliente.nombre,
                cliente.direccion,
                cliente.barrio,
                cliente.complemento,
            ];
            tableRows.push(clienteData);
        });

        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.text("Listado de Clientes", 14, 15);
        doc.save("clientes.pdf");
    };

    const handleFiltrarClientes = () => {
        // Implementa la lógica para filtrar los clientes
    };

    return (
        <div>
            <ToastContainer />
            <div className='deFlexContent'>
                <div className='deFlex2'>
                    <button className='excel' onClick={handleDownloadExcel}>
                        <FontAwesomeIcon icon={faArrowDown} /> Excel
                    </button>
                    <button className='pdf' onClick={handleDownloadPDF}>
                        <FontAwesomeIcon icon={faArrowDown} /> PDF
                    </button>
                </div>
                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroContacto}
                            onChange={(e) => setFiltroContacto(e.target.value)}
                            placeholder='Contacto'
                        />
                    </div>
                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroNombre}
                            onChange={(e) => setFiltroNombre(e.target.value)}
                            placeholder='Nombre'
                        />
                    </div>
                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroDireccion}
                            onChange={(e) => setFiltroDireccion(e.target.value)}
                            placeholder='Dirección'
                        />
                    </div>
                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroBarrio}
                            onChange={(e) => setFiltroBarrio(e.target.value)}
                            placeholder='Barrio'
                        />
                    </div>
                    <div className='inputsColumn'>
                        <input
                            type="text"
                            value={filtroComplemento}
                            onChange={(e) => setFiltroComplemento(e.target.value)}
                            placeholder='Complemento'
                        />
                    </div>
                    <button className='reload' onClick={handleFiltrarClientes}>
                        <FontAwesomeIcon icon={faSync} />
                    </button>
                </div>
            </div>
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Contacto</th>
                            <th>Nombre</th>
                            <th>Dirección</th>
                            <th>Barrio</th>
                            <th>Complemento</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientes.map(item => (
                            <tr key={item.contacto}>
                                <td>{item.contacto}</td>
                                <td>{item.nombre}</td>
                                <td>{item.direccion}</td>
                                <td>{item.barrio}</td>
                                <td>{item.complemento}</td>
                                <td>
                                    <button className='eliminar' onClick={() => eliminarCliente(item.contacto)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModalCliente(item)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {modalVisible && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={cerrarModal}>&times;</span>
                        <h2>Detalles del Cliente</h2>
                        <fieldset>
                            <legend>Contacto</legend>
                            <input
                                name='contacto'
                                value={clienteEditado.contacto}
                                onChange={handleChangeCliente}
                                readOnly
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Nombre</legend>
                            <input
                                name='nombre'
                                value={clienteEditado.nombre}
                                onChange={handleChangeCliente}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Dirección</legend>
                            <input
                                name='direccion'
                                value={clienteEditado.direccion}
                                onChange={handleChangeCliente}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Barrio</legend>
                            <input
                                name='barrio'
                                value={clienteEditado.barrio}
                                onChange={handleChangeCliente}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Complemento</legend>
                            <input
                                name='complemento'
                                value={clienteEditado.complemento}
                                onChange={handleChangeCliente}
                            />
                        </fieldset>
                        <div className='flexGrap'>
                            {Object.values(erroresValidacion).map((error, index) => (
                                <p key={index} className='error'>{error}</p>
                            ))}
                        </div>
                        <div className='flexGrap'>
                            <button className='save' onClick={guardarCambiosCliente}>Guardar</button>
                            <button className='cancel' onClick={cerrarModal}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
